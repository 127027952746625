import { store } from "@stencil/redux";
import { MapLayerState } from "../store/map/map.reducer";
import { MapLayerFactory } from "./map-layer-factory.service";
import { MapLayer } from "./maps/maps-base-layer";
import { MapGeoJSONLayer } from "./maps/maps-geojson-layer";
import { CoreoMapLayer } from "../types";
import { getAppMapLayer } from "../store/selectors";

export class MapLayerManagerService {

  private constructor() { }

  public static instance: MapLayerManagerService = new MapLayerManagerService();

  private mapLayers: Map<string, { layer: MapLayer; state: MapLayerState }> = new Map();
  private map: mapboxgl.Map;

  public setMap(map: mapboxgl.Map) {
    this.map = map;
  }

  public getMapLayerByLayerId(id: string) {
    for (const layer of this.mapLayers.values()) {
      if (layer.layer.hasLayer(id)) {
        return layer;
      }
    }
    return null;
  }

  async synchroniseLayers(layers: MapLayerState[]): Promise<void> {
    const targetIds = new Set(layers.map(l => l.id));
    const currentIds = new Set(this.mapLayers.keys());

    // Remove layers that are not present in the new state
    for (const id of currentIds) {
      if (!targetIds.has(id)) {
        const layerRef = this.mapLayers.get(id);
        if (layerRef) {
          layerRef.layer.remove();
          this.mapLayers.delete(id);
        }
      }
    }

    for (let i = 0; i < layers.length; i++) {
      const layerState = layers[i];
      let existing = this.mapLayers.get(layerState.id);
      if (layerState.enabled) {
        if (typeof existing === 'undefined') {
          const newLayer = await MapLayerFactory.instance.createMapLayer(layerState);
          existing = {
            layer: newLayer,
            state: layerState
          };
          this.mapLayers.set(layerState.id, existing);
          await newLayer.addTo(this.map);
        } else {
          await existing.layer.addTo(this.map);
          existing.layer.show();
        }
        existing.layer.move();
      } else {
        existing?.layer?.hide();
      }
    }

  }

  async refreshLayers() {
    const state = store.getState();
    for (const [_id, layerRef] of this.mapLayers.entries()) {
      const { layer } = layerRef;
      if (layer instanceof MapGeoJSONLayer) {
        const layerConfig: CoreoMapLayer = getAppMapLayer(layerRef.state.sourceId)(state);
        if (layerConfig) {
          layer.update(layerConfig);
        }
      }
    }
  }

}