import { store } from "@stencil/redux";
import { MapLayerState } from "../store/map/map.reducer";
import { MapLayer } from "./maps/maps-base-layer";
import { getAppCollection, getAppId, getAppMapLayer } from "../store/selectors";
import AppDatabase from "./db/app-db.service";
import { MapDataLayer } from "./maps/maps-data-layer";
import { CoreoMapLayer } from "../types";
import { MapRasterLayer } from "./maps/maps-raster-layer";
import { MapGeoJSONLayer } from "./maps/maps-geojson-layer";
import { MapCustomLayer } from "./maps/maps-custom-layer";

const boundsToCoordinates = (bounds: number[]): number[][] => {
  const sw = [bounds[0], bounds[1]];
  const ne = [bounds[2], bounds[3]];

  return [
    [sw[0], ne[1]],
    [ne[0], ne[1]],
    [ne[0], sw[1]],
    [sw[0], sw[1]]
  ];
};

export class MapLayerFactory {

  private constructor() { }

  public static instance: MapLayerFactory = new MapLayerFactory();

  private getCollectionFeatures(collectionId: number, projectId: number) {
    return AppDatabase.instance.items.findProjectMapFeatures(q => q.where('collectionId = ?', collectionId).where('projectId = ?', projectId));
  }

  private async createCollectionLayer(layerState: MapLayerState): Promise<MapLayer> {
    const state = store.getState();
    const projectId = getAppId(state);

    const collection = getAppCollection(layerState.sourceId)(state);
    const features = await this.getCollectionFeatures(layerState.sourceId, projectId);
    const mapLayer = new MapDataLayer();
    mapLayer.setFeatures(features);
    mapLayer.add(collection.id, collection.mapSort, collection.style);
    return mapLayer;
  }

  private async createCustomImageLayer(layerConfig: CoreoMapLayer, projectId: number): Promise<MapLayer> {
    const coordinates = layerConfig.layout ||
      (layerConfig.bounds ? boundsToCoordinates(layerConfig.bounds) : undefined);
    return new MapRasterLayer(layerConfig.source, coordinates, projectId);
  };

  async createCustomLayer(layerConfig: CoreoMapLayer): Promise<MapLayer> {
    const state = store.getState();
    const projectId = getAppId(state);

    if (layerConfig.sourceType === 'image') {
      return MapLayerFactory.instance.createCustomImageLayer(layerConfig, projectId);
    }
    if (layerConfig.sourceType === 'geojson') {
      return new MapGeoJSONLayer(layerConfig, projectId);
    }
    const mapLayer = new MapCustomLayer(layerConfig);
    await mapLayer.init();
    return mapLayer;
  }

  async createMapLayer(layerState: MapLayerState): Promise<MapLayer> {
    const state = store.getState();
    switch (layerState.layerType) {
      case 'collection': {
        return MapLayerFactory.instance.createCollectionLayer(layerState);
      }
      case 'custom': {
        const layerConfig: CoreoMapLayer = getAppMapLayer(layerState.sourceId)(state);
        return MapLayerFactory.instance.createCustomLayer(layerConfig);
      }
      default:
        throw new Error(`Unknown layer type: ${layerState.layerType}`);
    }
  }
}