import { EventEmitter } from '@stencil/core';
import { Unsubscribe, store } from '@stencil/redux';
import { BBox, Feature } from 'geojson';
import debounce from 'lodash.debounce';
import { AttributionControl, GeoJSONFeature, Map as MapboxMap, ScaleControl } from 'mapbox-gl';
import { MapInstanceState } from '../store/map/map.reducer';
import { getAppBounds, getAppConfigBingToken, getAppId, getAppNetworkConnected, getOfflineMapsMap } from '../store/selectors';
import { CoreoGeometry, CoreoMapFeature, CoreoMapFeatureType } from '../types';
import AppDatabase from './db/app-db.service';
import { geolocationStartTracking, state as geolocationState, onChange as onGeolocationChange } from './geolocation.service';
import { mapboxMapStyleUrl, matchMapboxStyleId } from './geometry.service';
import { MapFeaturesService } from './map-features.service';
import { MapIconService } from './map-icon.service';
import { MapLayerManagerService } from './map-layer-manager.service';
import { MapboxBoundsLayerControl, MapboxUserLocationControl, emptyStyle, pointToBox } from './mapbox.service';
import { MapLayer } from './maps/maps-base-layer';
import { safelyRemoveControl } from './maps/maps.utils';
import { OfflineMapsService } from './offline-maps.service';

const parseCoreoFeature = (feature: GeoJSONFeature): CoreoMapFeature => {
  let type: CoreoMapFeatureType;
  let sourceId: number;
  console.log('Feature', feature);

  if (feature.properties.hasOwnProperty('point_count')) {
    type = 'cluster';
  } else if (feature.properties.collectionId) {
    type = 'item';
    sourceId = feature.properties.collectionId;
  } else if (feature.properties.surveyId) {
    type = 'record';
    sourceId = feature.properties.surveyId;
  } else {
    type = 'custom';
  }

  return {
    type,
    sourceId,
    source: feature.source,
    geometry: feature.geometry as CoreoGeometry,
    properties: feature.properties,
    layerId: feature.layer?.id,
    id: feature.id as number
  };
}

export const mapInstanceDataLayersUpdated = (a: MapInstanceState, b: MapInstanceState): boolean => {
  const existingDataLayers = a.dataLayers.filter(l => l.enabled).map(l => l.sourceId);
  const currentDataLayers = b.dataLayers.filter(l => l.enabled).map(l => l.sourceId);
  return JSON.stringify(existingDataLayers) !== JSON.stringify(currentDataLayers);
}

export type MapFeatureFilter = () => (feature: Feature) => boolean;

export interface MapInterface {
  el: HTMLElement;
  previous: MapInterface;
  container: HTMLDivElement;

  bounds: any;
  boundsMaxZoom?: number;
  boundsPadding?: number;

  cluster: boolean;

  attributionPosition?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

  tracking: boolean;
  showRecenter: boolean;

  state: MapInstanceState;

  mapReady: EventEmitter<mapboxgl.Map>;
  mapReleased: EventEmitter<void>;
  mapStyleLoad: EventEmitter<mapboxgl.Map>;
  featureClicked: EventEmitter<CoreoMapFeature[]>;
  mapClicked: EventEmitter<void>;
  featureFocused: EventEmitter<any>;
  mapStateChanged: EventEmitter<MapInstanceState>;
  mapStateUpdated: EventEmitter<MapInstanceState>;

  featureFilter?: MapFeatureFilter;

  background?: boolean;
  layers?: MapLayer[];
}

interface MapInformation {
  bounds: mapboxgl.LngLatBounds;
  center: mapboxgl.LngLat;
  bearing: number;
  pitch: number;
  zoom: number;
}

class MapService {

  private constructor() { }

  public static instance: MapService = new MapService();

  private map: mapboxgl.Map;
  private mapContainer: HTMLDivElement;
  private previousState: MapInstanceState;

  private activeMap: MapInterface;
  private mapInfo: WeakMap<MapInterface, MapInformation> = new WeakMap();

  private intervalHandle: number;
  private networkSubscription: Unsubscribe;

  private locationHandle: MapboxUserLocationControl;
  private emptyHandle: number;
  private scaleControl: mapboxgl.ScaleControl;

  private appBoundsLayer: MapboxBoundsLayerControl;
  private networkConnected: boolean = false;

  public init() {
    AppDatabase.instance.events.addListener('syncDidUpdate', debounce(async (projectId: number, _records: boolean, config: boolean) => {
      if (this.activeMap && getAppId(store.getState()) === projectId) {
        await this.loadFeatures(projectId);
      }
      if (config) {
        MapLayerManagerService.instance.refreshLayers();
      }

    }, 300, { trailing: true }));
  }

  loadFeatures(projectId: number) {
    return MapFeaturesService.instance.loadFeatures(projectId, this.activeMap?.state, this.activeMap?.featureFilter);
  }

  private activeMapStateListener = async () => {
    await this.applyMapState(this.activeMap);
    this.activeMap.mapStateUpdated.emit(this.activeMap.state);
  };

  private onMapInitialLoad = () => {

    // Check to see if we are initializeing to an offline map
    const offlineMap = getOfflineMapsMap(this.activeMap.state.baseStyleId)(store.getState());
    if (typeof offlineMap !== 'undefined') {
      this.setMapStyle(this.activeMap.state.baseStyleId);
      return;
    }

    // Try to upgrade every 5 seconds
    this.intervalHandle = window.setInterval(this.upgradeMap, 5000);

    // Also try to upgrade as soon as the network becomes online
    this.networkConnected = getAppNetworkConnected(store.getState());
    this.networkSubscription = store.getStore().subscribe(() => {
      this.networkConnected = getAppNetworkConnected(store.getState())
      if (this.networkConnected) {
        this.upgradeMap();
      }
    });

    // Try to upgrade immediately
    if (this.networkConnected) {
      this.upgradeMap();
    }
    MapLayerManagerService.instance.setMap(this.map);
    MapIconService.instance.setMap(this.map);
  };

  private onTouchmove = () => {
    if (this.activeMap) {
      this.activeMap.tracking = false;
    }
  }

  private onRotateEnd = () => {
    if (this.activeMap) {
      this.activeMap.showRecenter = this.map.getBearing() !== 0;
    }
  }

  private onMapLoad = () => {
    this.map.on('touchmove', this.onTouchmove);
    this.map.on('rotateend', this.onRotateEnd);
    this.locationHandle = new MapboxUserLocationControl(geolocationState.location);
    this.map.addControl(this.locationHandle);
  };

  private onMapStyleLoad = async () => {
    let currentStyle: mapboxgl.StyleSpecification;
    try {
      currentStyle = this.map?.getStyle();
    } catch (e) {
      currentStyle = null;
    }

    if (currentStyle?.name === 'empty') {
      // If we don't yet have an empty handle, this is the first time
      if (typeof this.emptyHandle === 'undefined') {
        this.emptyHandle = window.setTimeout(this.onMapStyleLoad, 3000);
        return;
      }
    }

    if (this.emptyHandle) {
      window.clearTimeout(this.emptyHandle);
      this.emptyHandle = undefined;
    }

    // Attach the features layers
    // await this.featuresLayer.addTo(this.map);
    // await this.clustersLayer.addTo(this.map);
    await MapFeaturesService.instance.addTo(this.map);

    MapFeaturesService.instance.filterMapFeatures(this.activeMap.state);

    // Update clustering
    // this.updateClustering(this.activeMap.cluster);
    MapFeaturesService.instance.updateClustering(this.activeMap.cluster);

    // Render the maps state
    await this.applyMapState(this.activeMap);
    this.activeMap?.mapStyleLoad.emit(this.map);
  };

  private onMapClick = (e: mapboxgl.MapMouseEvent) => {
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(pointToBox(e.point, 15), {
      validate: false
    });

    if (clickedFeatures.some(s => s.layer?.id?.startsWith('MAP_GEOMETRY_SELECTOR'))) {
      return;
    }

    const features = clickedFeatures.filter(f => {
      return f.source.startsWith('coreo');
    })
      // Remove duplicates
      .filter((value, index, arr) => index === arr.findIndex(t => t.id === value.id))
      .map(f => parseCoreoFeature(f));

    if (features.length === 0) {
      this.fireMapClickEvent(e);
    }
    this.activeMap?.featureClicked.emit(features);
  };

  public fireMapClickEvent(e?: mapboxgl.MapMouseEvent) {
    this.map?.fire('coreo.mapClicked', { originalEvent: e });
    this.activeMap?.mapClicked.emit();
  }

  private async resolveMapStyle(styleId: string): Promise<mapboxgl.Style | string> {
    // Check bing
    if (styleId === 'bing') {
      return this.loadBingMapStyle();
    }

    // Check mapbox styles
    const mapboxStyle = matchMapboxStyleId(styleId);
    if (mapboxStyle) {
      return mapboxMapStyleUrl(mapboxStyle);
    }

    // Check offline maps
    const offlineMap = getOfflineMapsMap(styleId)(store.getState());
    if (offlineMap) {
      return OfflineMapsService.instance.getOfflineMap(offlineMap);
    }

    return emptyStyle;
  }

  private async loadBingMapStyle(): Promise<mapboxgl.Style> {
    const imagerySet = 'Aerial';
    const token = getAppConfigBingToken(store.getState());
    const culture = 'en-GB';
    const d = await fetch(`https://dev.virtualearth.net/REST/V1/Imagery/Metadata/${imagerySet}?output=json&uriScheme=https&include=ImageryProviders&key=${token}`);
    const data = await d.json();
    const resourceSets = data.resourceSets[0];
    const resources = resourceSets.resources;
    const resource = resources[0];

    const imageUrl: string = resource.imageUrl;
    const imageUrlSubdomains: string[] = resource.imageUrlSubdomains;

    const tiles = imageUrlSubdomains.map(subdomain => {
      return imageUrl.replace('{subdomain}', subdomain)
        .replace('{culture}', culture);
    });

    const minzoom = resource.zoomMin;
    // 20 seems to be the practical max zoom level, so don't go below that
    const maxzoom = Math.min(resource.zoomMax, 20);
    const attribution = [
      `<a target="_blank" href="https://www.bing.com/maps" data-bm="101">© 2025 Microsoft</a>`,
      `&copy; TomTom`,
      `<a target="_blank" href="https://www.openstreetmap.org/copyright" data-bm="101">© OpenStreetMap</a>`,
      `Earthstar Geographics  SIO`
    ].join(', ');

    return {
      version: 8,
      glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
      name: 'Bing Aerial',
      sources: {
        'bing': {
          type: 'raster',
          tiles: tiles,
          tileSize: 256,
          minzoom,
          maxzoom,
          data: {
            attribution
          }
        }
      },
      layers: [{
        id: 'bing',
        type: 'raster',
        source: 'bing'
      }]
    };
  }

  private async setMapStyle(styleId: string) {
    const style = await this.resolveMapStyle(styleId);
    this.map.setStyle(style, {
      diff: false,
      localFontFamily: '',
      localIdeographFontFamily: ''
    });
  }

  private upgradeMap = async () => {
    if (!this.networkConnected) {
      return;
    }

    try {
      const currentStyle = this.map?.getStyle();
      if (!currentStyle) {
        return;
      }

      if (currentStyle.name === 'empty') {
        this.setMapStyle(this.activeMap.state.baseStyleId);
      } else {
        this.cancelUpgradeMap();
      }
    } catch (e) {
      console.warn(e);
    }
  }

  private cancelUpgradeMap() {
    if (typeof this.intervalHandle !== 'undefined') {
      window.clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
    if (typeof this.networkSubscription !== 'undefined') {
      this.networkSubscription();
      this.networkSubscription = undefined;
    }
  }

  private initializeMap(request: MapInterface) {
    const { container, bounds, boundsMaxZoom, boundsPadding } = request;

    // Create a container for our map
    this.mapContainer = document.createElement('div');
    this.mapContainer.classList.add('map-master');

    // Append it into our first requester
    container.prepend(this.mapContainer);

    const options: mapboxgl.MapOptions = {
      container: this.mapContainer,
      style: emptyStyle,
      bounds,
      fitBoundsOptions: {
        maxZoom: boundsMaxZoom ?? 12,
        padding: boundsPadding ?? 40
      },
      attributionControl: false,
      trackResize: false,
      projection: 'mercator' as any,
      transformRequest: OfflineMapsService.instance.offlineMapTransformRequestFunction,
      fadeDuration: 0
    };
    this.map = new MapboxMap(options);
    this.map.addControl(new AttributionControl(), request.attributionPosition ?? 'bottom-right');

    // Setup upgrade handles
    this.map.once('load', this.onMapInitialLoad);
    this.map.on('load', this.onMapLoad);
    this.map.on('style.load', this.onMapStyleLoad);
    // this.map.on('styleimagemissing', this.imageMissingHandler)

    // Setup generic coreo map feature click handler
    this.map.on('click', this.onMapClick);

    geolocationStartTracking();

    onGeolocationChange('location', location => {
      if (this.locationHandle) {
        this.locationHandle.setPosition(location);
      }
      // if (this.tracking) {
      //   this.centerMap({
      //     center: geolocationState.location as LngLatLike
      //   });
      // }
    });

    onGeolocationChange('heading', heading => {
      if (this.locationHandle) {
        this.locationHandle.setHeading(heading);
      }
    });

    return this.map;
  }

  private storeMapInfo(element: MapInterface) {
    this.mapInfo.set(element, {
      bounds: this.map.getBounds(),
      center: this.map.getCenter(),
      zoom: this.map.getZoom(),
      bearing: this.map.getBearing(),
      pitch: this.map.getPitch()
    });
  }

  public async requestMap(element: MapInterface) {
    // If we have an existing map, store it's information first
    if (this.activeMap) {
      this.storeMapInfo(this.activeMap);
      this.activeMap.el.removeEventListener('mapStateChanged', this.activeMapStateListener);
      this.activeMap.container.classList.add('map-hidden');
      (this.activeMap.layers || []).map(l => l.remove());
      this.activeMap.mapReleased.emit();
    }

    // Set the currently active maps previous pointer to the incoming map
    element.previous = this.activeMap;

    // Set our new active map
    this.activeMap = element;
    this.activeMap.el.addEventListener('mapStateChanged', this.activeMapStateListener, {
      passive: true
    });

    if (!this.map) {
      return this.initializeMap(element);
    }

    // Move the map now it's hidden
    const attachMap = async () => {

      // Hide the container element whilst we move the map into it
      element.container.classList.add('map-hidden');

      // Move the map into the container
      element.container.appendChild(this.mapContainer);

      // Resize the map in its new location
      this.map.resize();

      // Update the center of the map
      const { bounds, boundsPadding, boundsMaxZoom } = element;

      if (bounds) {
        this.map.fitBounds(bounds, {
          padding: boundsPadding,
          maxZoom: boundsMaxZoom,
          duration: 0,
          maxDuration: 0
        });
      }

      // Update clustering
      MapFeaturesService.instance.updateClustering(element.cluster);

      // Apply the state
      if (await this.applyMapState(element)) {
        element.mapReady.emit(this.map);
        element.mapStyleLoad.emit(this.map);
      }

      // Reveal the map
      setTimeout(() => element.container.classList.remove('map-hidden'), 100);
    };
    requestAnimationFrame(attachMap);
    return this.map;
  }

  private reconnectMap = async () => {
    // Move the map to its new location and resize
    this.activeMap.container.appendChild(this.mapContainer);
    this.map.resize();

    // Center the map back to its previous location
    const info = this.mapInfo.get(this.activeMap);
    const { bounds, bearing, pitch } = info;
    const { center, zoom } = this.map.cameraForBounds(bounds, {
      padding: 0
    });

    this.map.jumpTo({
      center,
      zoom,
      bearing,
      pitch
    });

    // Attach listener
    this.activeMap.el.addEventListener('mapStateChanged', this.activeMapStateListener, {
      passive: true
    });

    // Apply state
    MapFeaturesService.instance.updateClustering(this.activeMap.cluster);
    this.activeMap.showRecenter = bearing !== 0;
    await this.applyMapState(this.activeMap);

    // Re-trigger the map events
    this.activeMap.mapReady.emit(this.map);
    this.activeMap.mapStyleLoad.emit(this.map);

    // Finally remove the hidden
    setTimeout(() => this.activeMap.container.classList.remove('map-hidden'), 100);
  }

  public async releaseMap(element: MapInterface) {
    this.activeMap = element.previous;
    element.el.removeEventListener('mapStateChanged', this.activeMapStateListener);
    element.previous = undefined;
    (element.layers || []).map(layer => layer.remove());
    element.mapReleased.emit();

    // Persist the base style
    if (this.activeMap) {
      this.activeMap.state.baseStyleId = element.state.baseStyleId;
      if (!this.activeMap.background) {
        requestAnimationFrame(this.reconnectMap);
      }
    }
  }

  public getBounds(): BBox {
    return this.map?.getBounds().toArray().flat() as BBox;
  }

  public getMap() {
    return this.map;
  }

  private updateScaleBar(showScaleBar: boolean) {
    // If we have the control and we don't want it, remoe it
    const hasControl = this.map.hasControl(this.scaleControl);
    if (!showScaleBar && hasControl) {
      this.map.removeControl(this.scaleControl);
    } else if (showScaleBar) {
      if (typeof this.scaleControl === 'undefined') {
        this.scaleControl = new ScaleControl({
          unit: 'metric',
          maxWidth: 240
        });
      }
      if (!hasControl) {
        this.map.addControl(this.scaleControl, 'top-left');
      }
    }
  }

  private updateProjectBounds(showProjectBounds: boolean) {
    const appBounds = getAppBounds(store.getState());
    // If we are showing bounds, and we don't already have one
    if (this.appBoundsLayer) {
      safelyRemoveControl(this.map, this.appBoundsLayer);
    }

    if (showProjectBounds && appBounds) {
      this.appBoundsLayer = new MapboxBoundsLayerControl(appBounds);
      this.map.addControl(this.appBoundsLayer);
    } else if (this.appBoundsLayer && (!showProjectBounds || !appBounds)) {
      this.appBoundsLayer = undefined;
    }
  }

  public triggerFeaturesRefresh(element: MapInterface) {
    if (this.activeMap === element) {
      console.log('Triggering features refresh');
      MapFeaturesService.instance.filterMapFeatures(element.state, element.featureFilter);
    }
  }

  public notifyBackgroundChange(element: MapInterface) {
    if (element === this.activeMap && !element.background && !element.el.contains(this.mapContainer)) {
      this.reconnectMap();
    }
  }


  public async applyMapState(element: MapInterface): Promise<boolean> {

    const { state } = element;

    // First check if the base style has changed
    if (this.previousState && this.previousState.baseStyleId !== state.baseStyleId) {
      this.setMapStyle(state.baseStyleId);
      this.previousState = state;

      // If we are changing the entire style, this process will resume
      // once the style has loaded. So return now
      return false;
    }

    // Handle Scale Bar
    this.updateScaleBar(state.showScaleBar);

    // Handle Project Bounds
    this.updateProjectBounds(state.showAppBounds);

    // Render base layers
    await MapLayerManagerService.instance.synchroniseLayers(state.layers);

    // Render data layers
    await MapFeaturesService.instance.applyMapDataLayers(state, this.previousState);

    (element.layers || []).map(l => l.addTo(this.map));

    this.previousState = state;
    return true;
  }
}

export default MapService;
