import { Feature, FeatureCollection } from "geojson";
import { CoreoMapLayer } from "../../types";
import { MapLayer } from "./maps-base-layer";
import { createDefaultDataLayerStyle, getMapGeoJSONUrl, linePaint, pointPaint, polygonBorderPaint, polygonFillPaint, safelyAddLayer, safelyAddSource } from "./maps.utils";

export class MapGeoJSONLayer extends MapLayer {
  constructor(private config: CoreoMapLayer, private projectId: number) {
    super();
  }

  private features: FeatureCollection;

  private createLayerId(type: string): string {
    return `${this.id}-geojson-${type}-${this.config.id}`;
  }

  private async loadSourceData(): Promise<FeatureCollection> {

    if (typeof this.features !== 'undefined') {
      return this.features;
    }

    try {
      const data = await getMapGeoJSONUrl(this.projectId, this.config.source);
      const sourceData = await fetch(data);
      this.features = await sourceData.json();
    } catch (e) {
      console.error('Error loading source data', e);
      this.features = { type: 'FeatureCollection', features: [] };
    }
    return this.features;
  }

  public async getFeature(id: number): Promise<Feature> {
    return this.features.features[id];
  }

  async addTo(map: mapboxgl.Map): Promise<void> {
    this.map = map;
    const data = await this.loadSourceData();

    safelyAddSource(map, this.id, {
      type: 'geojson',
      generateId: true,
      data
    });

    const style = {
      ...createDefaultDataLayerStyle(),
      ...this.config.style
    };

    const [polygonP, polygonbP, lineP, pointP] = await Promise.all([
      polygonFillPaint(style),
      polygonBorderPaint(style),
      linePaint(style),
      pointPaint(style)
    ]);

    safelyAddLayer(map, {
      id: this.createLayerId('polygon'),
      type: 'fill',
      source: this.id,
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: polygonP
    });

    safelyAddLayer(map, {
      id: this.createLayerId('polygon-outline'),
      type: 'line',
      source: this.id,
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: polygonbP
    });

    safelyAddLayer(map, {
      id: this.createLayerId('linestring'),
      type: 'line',
      source: this.id,
      filter: ["==", ["geometry-type"], "LineString"],
      paint: lineP
    });

    safelyAddLayer(map, {
      id: this.createLayerId('point'),
      type: 'circle',
      source: this.id,
      filter: ["==", ["geometry-type"], "Point"],
      paint: pointP
    });
  }

  layerIds(): string[] {
    return ['polygon', 'polygon-outline', 'linestring', 'point'].map(a => this.createLayerId(a));
  }

  async update(config: CoreoMapLayer): Promise<void> {
    this.config = config;
    const style = {
      ...createDefaultDataLayerStyle(),
      ...this.config.style
    };

    const [polygonP, polygonbP, lineP, pointP] = await Promise.all([
      polygonFillPaint(style),
      polygonBorderPaint(style),
      linePaint(style),
      pointPaint(style)
    ]);

    for (const k in polygonP) {
      this.map?.setPaintProperty(this.createLayerId('polygon'), k as keyof mapboxgl.FillLayerSpecification['paint'], polygonP[k]);
    }

    for (const k in polygonbP) {
      this.map?.setPaintProperty(this.createLayerId('polygon-outline'), k as keyof mapboxgl.LineLayerSpecification['paint'], polygonbP[k]);
    }

    for (const k in lineP) {
      this.map?.setPaintProperty(this.createLayerId('linestring'), k as keyof mapboxgl.LineLayerSpecification['paint'], lineP[k]);
    }

    for (const k in pointP) {
      this.map?.setPaintProperty(this.createLayerId('point'), k as keyof mapboxgl.CircleLayerSpecification['paint'], pointP[k]);
    }
  }
}
